import Vue from 'vue';
import axios from 'axios';
import router from '../router/index';
import RouterHelper from '..//router/routerhelper';
import store from '../store';
import {reconnectWebsocket} from './websocket';
import {setRoles, setRight} from './ortutils';

let isRefreshing = false;
let refreshSubscribers = [];

// Frissítési kérés figyelők (várakoztatott kérések új access tokenre)
const subscribeTokenRefresh = (cb) => {
    refreshSubscribers.push(cb);
};

const onRefreshed = (newToken) => {
    refreshSubscribers.forEach((cb) => cb(newToken));
    refreshSubscribers = [];
};

// Létrehozzuk az instance-ot, amit az összes komponensben el lehet érni
const createApiInstance = (axiosInstance, Vue) => {
	axiosInstance.interceptors.request.use((config) => {
		const token = store.getters.getToken;
		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`;
		}
		return config;
	});
	instance401Checker(axiosInstance);
	Vue.prototype.$api = axiosInstance;
}

// 401 kezelése és automatikus refresh token küldése
const instance401Checker = (axiosInstance) => {
    axiosInstance.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalRequest = error.config;
            if (error.response && error.response.status === 401 && router.currentRoute.path !== '/login') {
                if (!originalRequest._retry) {
                    originalRequest._retry = true;
                    // Ha már folyamatban van egy token frissítés, akkor várakozunk
                    if (isRefreshing) {
                        return new Promise((resolve) => {
                            subscribeTokenRefresh((newToken) => {
                                originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                                resolve(axiosInstance(originalRequest));
                            });
                        });
                    }
					// Elindul a refresh folyamat
                    isRefreshing = true;
                    try {
						const apiUrl = process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_DEVSERVER : process.env.VUE_APP_PRODUCTSERVER;
                        const response = await axios.post(apiUrl + '/api/refresh', {}, { withCredentials: true });
                        const newToken = response.data.access_token;
						store.dispatch('setUID', response.data.uid);
                        store.dispatch('setToken', newToken);
						RouterHelper.role = response.data.roles;
						RouterHelper.right = response.data.rights;
						setRoles(response.data.roles);
						setRight(response.data.rights);
                        onRefreshed(newToken);
                        isRefreshing = false;
                        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                        return axiosInstance(originalRequest);
                    }
					catch (refreshError) {
                        isRefreshing = false;
						store.dispatch('clearToken');
                        router.push('/login');
                        return Promise.reject(refreshError);
                    }
                }
                // Ha már próbáltunk egyszer refresh-elni, de az sikertelen volt, irány a login
                router.push('/login');
            }
            return Promise.reject(error);
        }
    );
};

// lekérdezzük a felhasználó geolokációs adatait, s ez alapján állítjuk be a default nyelvet
export const getGeoLocation = async (store) => {
    try {
        const apiUrl = process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_DEVSERVER : process.env.VUE_APP_PRODUCTSERVER;
        const response = await axios.get(apiUrl + '/api/getDefaultLang', {}, { withCredentials: false });
        const lang = response.data.lang || 'hu';
        // Tároljuk a nyelvet a Vuex state-ben
        store.dispatch('changeLocale', lang);
        return lang;
    } catch (error) {
        console.error('Nem sikerült lekérni a nyelvet:', error);
        return 'hu'; // Ha valami hiba van, alapértelmezett legyen a magyar
    }
};

// A refresh token folyamata
export const refreshToken = async () => {
    try {
        const apiUrl = process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_DEVSERVER : process.env.VUE_APP_PRODUCTSERVER;
        const response = await axios.post(apiUrl + '/api/refresh', {}, { withCredentials: true });
		store.dispatch('setUID', response.data.uid);
		store.dispatch('setToken', response.data.access_token);
		store.commit('set', ['isAuthenticated', true]);
		RouterHelper.role = response.data.roles;
		RouterHelper.right = response.data.rights;
		setRoles(response.data.roles);
		setRight(response.data.rights);
        // WebSocket újrainicializálása új tokennel
        Vue.prototype.$websocket = window.Echo = await reconnectWebsocket(response.data.access_token);
        return response.data.access_token;
    } catch (error) {
        return null;
    }
};

export default {
	install(Vue) {
		Vue.mixin({
			created() {
				const apiUrl = process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_DEVSERVER : process.env.VUE_APP_PRODUCTSERVER;
				const axiosInstance = axios.create({
					baseURL: apiUrl + '/api/',
					withCredentials: true, // A cookie küldéshez van szükség erre
				});
				createApiInstance(axiosInstance, Vue);
			}
		});
		// Az általánosított fileUpload függvény
		Vue.prototype.$fileUpload = (attachment, url, data = null) => {
			if (attachment.file || attachment) {
				const file = attachment.file ? attachment.file : attachment;
				const token = store.getters.getToken;
				let formData = new FormData();
				formData.append('file', file);
				if (data) {
					if (data.pid) formData.append('pid', data.pid);
					if (data.mid) formData.append('mid', data.mid);
				}
				const config = {
					method: 'post',
					url: url,
					headers: {
						'Authorization': `Bearer ${token}`,
						'Content-Type': 'multipart/form-data'
					},
					data: formData
				};
				return axios(config)
			}
		}
	}
}
