import Vue from 'vue';
import Vuex from 'vuex';
import {refreshToken} from './plugins/api.js';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

let state = {
	uid: null,
	actYear: null,
	userEmail: null,
	currentLang: '',
	projectView: 'card',
	sidebarShow: 'responsive',
	sidebarMinimize: true,
	asideShow: false,
	darkMode: false,
	loggedInterval: null,
	isAuthenticated: false,
	unreadNotifications: [],
	accessToken: null,
}

const mutations = {
	toggleSidebarDesktop( status ) {
		const sidebarOpened = [true, 'responsive'].includes(status.sidebarShow)
		status.sidebarShow = sidebarOpened ? false : 'responsive'
	},
	toggleSidebarMobile( status ) {
		const sidebarClosed = [false, 'responsive'].includes(status.sidebarShow)
		status.sidebarShow = sidebarClosed ? true : 'responsive'
	},
	set( status, [variable, value] ) {
		status[variable] = value
	},
	toggle( status, variable ) {
		status[variable] = !status[variable]
	},
    // Token beállítása (Csak memóriában!)
    SET_TOKEN(state, token) {
        state.accessToken = token;
        state.isAuthenticated = !!token;
    },
    CLEAR_TOKEN(state) {
        state.accessToken = null;
        state.isAuthenticated = false;
    },
	SET_UID(state, uid) {
		state.uid = uid;
	},
	CLEAR_UID(state) {
		state.uid = null;
	},
	SET_EMAIL(state, email) {
		state.userEmail = email;
	},
	CLEAR_EMAIL(state) {
		state.userEmail = null;
	},
	updateLocale( status, newLocale ) {
		Vue.set(status, 'currentLang', newLocale);
	},
    setUnreadNotifications(state, notifications) {
		state.unreadNotifications = notifications;
	},
	addUnreadNotification(state, notification) {
		state.unreadNotifications.push(notification);
	},
	removeUnreadNotification(state, notificationId) {
		const index = state.unreadNotifications.findIndex(
			notification => notification.id === notificationId
		);
		if (index !== -1) {
			state.unreadNotifications.splice(index, 1);
		}
	},
	removeAllUnreadNotification(state) {
		state.unreadNotifications = [];
	},
}

const actions = {
	changeLocale({ commit }, newLocale) {
		commit('updateLocale', newLocale)
	},
    // Token mentése memóriába
    setToken({ commit }, token) {
        commit('SET_TOKEN', token);
    },
    // Token törlése (kilépésnél)
    clearToken({ commit }) {
        commit('CLEAR_TOKEN');
    },
	setUID({ commit}, uid) {
		commit('SET_UID', uid);
	},
	clearUID({ commit }) {
		commit('CLEAR_UID');
	},
	setEmail({ commit }, email) {
		commit('SET_EMAIL', email);
	},
	clearEmail({ commit }) {
		commit('CLEAR_EMAIL');
	},
	// Token frissítés kezdeményezése
	async refreshToken() {
		return await refreshToken();
	},
}

const getters = {
	getUID: (state) => state.uid,
	getLocale: (state) => state.currentLang,
	// Token getter
	getToken: (state) => state.accessToken,
	getEmail: (state) => state.userEmail,
	isAuthenticated: (state) => state.isAuthenticated,
}

export default new Vuex.Store({
	state,
	mutations,
	actions,
	getters,
    // Fontos: A tokeneket csak a memóriában tároljuk
    plugins: [
        createPersistedState({
			// Csak ezek tárolódnak localStorage-ben!
            paths: ['actYear', 'currentLang', 'projectView', 'sidebarShow', 'sidebarMinimize', 'asideShow', 'darkMode'],
        }),
    ],
});
