import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import store from '../store';
import WebsocketHelper from './websockethelper';
import { initChannelListener } from './websockethandler';

let EchoInstance = null;

export const reconnectWebsocket = (token, Vue = null) => {
    return new Promise((resolve) => {
		if (!EchoInstance) {

			window.Pusher = Pusher;
			window.Pusher.logToConsole = process.env.VUE_APP_WEBSOCKET_LOG_TO_CONSOLE === 'true';

			EchoInstance = new Echo({
				broadcaster: 'pusher',
				key: process.env.VUE_APP_WEBSOCKET_APP_KEY,
				cluster: process.env.VUE_APP_WEBSOCKET_APP_CLUSTER,
				wsHost: window.location.hostname,
				wsPort: process.env.VUE_APP_WEBSOCKET_APP_PORT,
				wssPort: process.env.VUE_APP_WEBSOCKET_APP_PORT,
				forceTLS: process.env.VUE_APP_WEBSOCKET_APP_FORCETLS === 'true',
				enabledTransports: ['ws', 'wss'],
				encrypted: true,
				auth: {
					headers: {
						'Authorization': 'Bearer ' + (token || store.getters.getToken()),
					},
					method: 'post'
				},
			});

			window.Echo = EchoInstance;
			if (Vue) Vue.prototype.$websocket = EchoInstance;

			// Figyeljük, hogy mikor csatlakozik sikeresen
			const checkConnection = () => {
				if (EchoInstance.connector.pusher.connection.state === 'connected') {
					initChannelListener(EchoInstance, store.getters.getUID);
					resolve(EchoInstance);
				} else {
					setTimeout(checkConnection, 100);
				}
			};

			checkConnection();

		}
		else {
			// Ha már létezik az EchoInstance, feloldjuk a Promiset
			resolve();
		}
    });
}

export default {
	install(Vue) {
		// Elindítjuk a websocket kapcsoaltot
		Vue.prototype.$initWebSocket = function (token) {
			let authToken;
			const userToken = store.getters.getToken;
			if (!token) authToken = userToken;
			else authToken = token;
			//ha frissítjük F5-tel a böngészőt, akkor még a .envből olvasunk, de feloldható, hogyha a SessionStorage-ben titkosítva elmentjük
			if (!WebsocketHelper.key) {
				//TODO: ezeket az EchoInstance alá kell bevezetni, de vmiért üresek a mezők, valszeg szinkron probléma
				//TODO: A devre ez menjen fel jól, h a ws-sel minden oké legyen
				WebsocketHelper.key = process.env.VUE_APP_WEBSOCKET_APP_KEY;
				WebsocketHelper.cluster = process.env.VUE_APP_WEBSOCKET_APP_CLUSTER;
			}
			reconnectWebsocket(authToken, Vue);
		};
		// Bezárjuk a WebSocket kapcsolatot
		Vue.prototype.$closeWebSocket = () => {
			if (EchoInstance) {
				EchoInstance.disconnect();
				EchoInstance = null;
			}
		};

	}
}
