import Crypto from 'crypto-js';

let code = null;
let authKey = null;
let userRole = null;
let userRight = null;
let rightAdmin = false;
let showDemoData = false;
let projectRoleID = null;
let rightResponder = false;
let rightResearcher = false;
let showDemoDashBoard = false;

/**
 * A rendszeren belül egy üzenet küldésének általános használata
 *
 * @param {object} self
 * @param {string} textMessage
 * @param {string} color
 * @param {int} dismissSecs
 */
let setInfoBar = (self, textMessage, color, dismissSecs = 3, loading = false) => {
    const data = {
        show: true,
        text: textMessage,
        countDown: dismissSecs,
        colorAlert: color,
        loading: loading,
    }
    self.$eventBus.$emit('eventSetInfoBarDatas', data);
}

let getStorage = (key) => {
    return window.localStorage.getItem(key);
}

let getSecuredStorage = (key) => {
	const value = window.localStorage.getItem(key);
	try {
		return value ? Crypto.AES.decrypt(value, code).toString(Crypto.enc.Utf8) : null;
	}
	catch(error) {
		console.log(error);
		removeFromStorage(key);
		removeFromStorage('vuex');
		removeFromStorage('pusherTransportNonTLS');
		location.replace(window.location.origin + '/#/login');
	}
}

let setRightProp = (right) => {
	switch (right) {
		case 'admin':
			rightAdmin = true;
			rightResearcher = rightResponder = false;
		break;
		case 'researcher':
			rightResearcher = true;
			rightAdmin = rightResponder = false;
		break;
		case 'user':
			rightResponder = true;
			rightResearcher = rightAdmin = false;
		break;
		default:
		break;
	}
}

const setStorage = (key, value) => {
    window.localStorage.setItem(key, value);
}

const setSecuredStorage = (key, value) => {
	const encrypted = Crypto.AES.encrypt(value, code).toString();
	window.localStorage.setItem(key, encrypted);
}

const removeFromStorage = (key) => {
    window.localStorage.removeItem(key);
}

/**
 * A trix editor automatikus frissítése
 *
 * @param {string} elem
 * @param {object} langs
 * @param {string} htmlContent
 */
const refreshTrixEditor = (elem, langs, htmlContent) => {
	//az alábbi sorok a tabváltás miatt kellenek, h a trix biztosan frissüljön
	const toolBars = document.querySelectorAll('trix-toolbar');
	const elemNum = langs.length;
	if (toolBars) {
		//ezt azért kell így, mert ahány nyelvi elem van, annyi tab, s rajta pedig ennyi db editor lesz
		const removeToolbars = Array.from(toolBars).slice(-elemNum);
		removeToolbars.forEach(toolbar => toolbar.remove());
	}
	//Mivel a trix nem frissíti magát auto a v-model alapján, így kell ez a kis megoldás
	const trixEditor = document.querySelector(elem);
	if (trixEditor) trixEditor.editor.loadHTML(htmlContent);
}

export const getAuth = () => {
	return getSecuredStorage(authKey) ? JSON.parse(getSecuredStorage(authKey)) : null;
}

export const setRight = (right) => {
	userRight = right;
	setRightProp(right);
}

export const setRoles = (roles) => {
	userRole = roles;
}

export const setProjectRoleID = (roleID) => {
	projectRoleID = roleID;
}

export const logoutFromORT = (self) => {
	self.$api.post('logout')
	.then(() => {
		self.$store.dispatch('clearUID');
		self.$store.dispatch('clearEmail');
		self.$store.dispatch('clearToken');
		self.$store.commit('set', ['loggedInterval', null]);
		self.$store.commit('set', ['isAuthenticated', false]);
		self.$router.push({ path: '/login' });
	}).catch((error) => {
		console.error(error);
	});
}

/**
 * Még másolatot készítünk a kapott objektumról, h ennek biztosan más legyen a referenciája,
 * mint egy hasonló módon tárolt változó értéke, mert akkor nem lesz egyezés a 2 változó közt
 *
 * @param obj object
 */
export const deepClone = (obj) => {
	if (obj === null || typeof obj !== 'object') {
		return obj;
	}

	if (Array.isArray(obj)) {
		return obj.map(deepClone);
	}

	const clonedObj = {};
	for (const key in obj) {
		clonedObj[key] = deepClone(obj[key]);
	}

	return clonedObj;
}

export const getDemoDashboard = () => {
	return showDemoDashBoard;
}

export const validatingEmail = (emailAdd) => {
	const emailLocalPart = '[^<>()[\\].,;:\\s@"]+(\\.[^<>()[\\].,;:\\s@"]+)*|"(.+)"';
	const ipAddressPart = '\\[[0-9]{1,3}(\\.[0-9]{1,3}){3}\\]';
	const domainPart = '[a-zA-Z\\-0-9]+(\\.[a-zA-Z\\-0-9]+)*\\.[a-zA-Z]{2,}';
	const re = new RegExp(`^(${emailLocalPart})@(${ipAddressPart}|${domainPart})$`);
    return re.test(String(emailAdd).toLowerCase());
}

export const validatingPassword = (pwd) => {
    const pwdTemp = /^(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,40}$/;
    return pwdTemp.test( pwd );
}

export default {
	install(Vue) {
		Vue.mixin({
			created() {
				const defNum = this.$defaultNumber;
				code = String.fromCharCode(
					(defNum+15), (defNum+9), (defNum+40), (defNum+53), (defNum-30),
					(defNum+51), (defNum-10), (defNum-11), (defNum+21), (defNum+59)
				);
				const agent = navigator.userAgent;
				code = `${code}|${agent}`;
				authKey = this.$authKey;
				Vue.prototype.$getAuth = getAuth;
				Vue.prototype.$setRight = setRight;
				Vue.prototype.$setRoles = setRoles;
				Vue.prototype.$userRole = userRole;
				Vue.prototype.$deepClone = deepClone;
				Vue.prototype.$userRight = userRight;
				Vue.prototype.$rightAdmin = rightAdmin;
				Vue.prototype.$showDemoData = showDemoData;
				Vue.prototype.$projectRoleID = projectRoleID;
				Vue.prototype.$rightResponder = rightResponder;
				Vue.prototype.$rightResearcher = rightResearcher;
				Vue.prototype.$validatingEmail = validatingEmail;
				Vue.prototype.$validatingPassword = validatingPassword;
				Vue.prototype.$setProjectRoleID = setProjectRoleID;
				Vue.prototype.$getDemoDashboard = getDemoDashboard;
				Vue.prototype.$getStorage = getStorage;
				Vue.prototype.$setStorage = setStorage;
				Vue.prototype.$setInfoBar = setInfoBar;
				Vue.prototype.$logoutFromORT = logoutFromORT;
				Vue.prototype.$getSecureStorage = getSecuredStorage;
				Vue.prototype.$setSecureStorage = setSecuredStorage;
				Vue.prototype.$removeFromStorage = removeFromStorage;
				Vue.prototype.$refreshTrixEditor = refreshTrixEditor;
			}
		});
	}
}
